<template>
    <div>
      <h2 class="font-semibold text-lg mb-4 mt-4 flex flex-row items-center"><div> Введите данные ключи, получить их можно на портале MyAcuvuePro </div></h2>
      <div class="bg-white px-5 py-4 rounded-md grid grid-cols-1 gap-4">
        <Error v-if="error">{{error}}</Error>
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="myacuvue_client">Client</label>
          <input class="w-full" v-model="myacuvue.client" type="text" id="myacuvue_client" placeholder="8H9XOcFOTKOnJ33wCmdAGHA80Wl3v2Ij" />
        </div>

        <div class="col-span-2">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="myacuvue_secret">Secret</label>
          <input class="w-full" v-model="myacuvue.secret" type="text" id="myacuvue_secret" placeholder="xKJKJ2ENzNmmHRTv" />
        </div>
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="myacuvue_store_id">Store_id</label>
          <input v-model="myacuvue.store_id" type="text" id="myacuvue_store_id" placeholder="RUSq5345" />
        </div>
      </div>

      <div class="">
        <button class="btn-action mt-4" @click="saveSettings()" :class="{ busy: busy }">Сохранить
        </button>
      </div>

    </div>
</template>

<script>
export default {
    props: [],
    data() {
        return {
          myacuvue: {
            client:"",
            secret:"",
            store_id:""
          },
          busy: false,
          error: false,
        };
    },
    mounted() {
      // this.getTaskList()
      this.getSettings();
    },
    methods: {
      async getSettings() {
        let { success, msg, data } = await this.$api.get("/settings?type=myacuvue");
        if (success) {
          this.myacuvue = data.data;
        }
      },
      async saveSettings() {
        this.busy = true;
        this.error = "";

        let { success, msg, data } = await this.$api.post("/settings/myacuvue", {
          data: {
            client:this.myacuvue.client,
            secret:this.myacuvue.secret,
            store_id:this.myacuvue.store_id
          }
        });

        if (!success) this.error = msg;

        setTimeout(() => {
          this.busy = false;
        }, 250);
      },
    }
}
</script>