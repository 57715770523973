<template>
  <Modal :styles="'min-w-4xl max-w-4xl'" :success="status == 'save-success'">
    <div class="flex flex-col md:flex-row justify-between">
      <div>
        <h3 class=" mb-6">
          <div class="px-3 mb-2 inline-block py-1 rounded-md text-xs bg-green-500 text-white mr-2 font-semibold"
            v-if="!customer.id">
            Новый клиент
          </div>
          <div class="text-black font-bold text-xl" v-if="customer && customer.fio">
            {{ customer.fio }}
              <img v-if="customer.fromAcuvue" src="/img/myacuvue-logo.svg" class="inline w-4" alt="добавлен через MyAcuvue">
              <img v-if="customer.itigrisId" src="/img/itigris-logo.svg" class="inline w-4"  alt="добавлен через itigris">
          </div>
        </h3>
      </div>
      <div>
        <!-- If customer is empty, search by phone -->
        <template v-if="false">
          <auto-suggest styles="w-full mb-4 py-2 px-3" placeholder="Поиск по телефону..." v-model="searchByPhoneString"
            :component="'Customer'" :field="'phone'" v-on:select="setCustomer" :focus="true">
            <template v-slot:default="slotProps">
              <div>
                <span class="text-sm font-normal">
                  <i v-if="slotProps.item.fromAcuvue" class="text-blue-500 font-bold text-xs">
                    @acuvue
                  </i>
                  {{ slotProps.item.lastname }} {{ slotProps.item.firstname }}
                </span>
                <div class="text-xs">{{ slotProps.item.phone }} {{ slotProps.item.email }}</div>
              </div>
            </template>
          </auto-suggest>
        </template>
      </div>
    </div>

    <div class="flex flex-row mt-2 mb-6 space-x-6 border-b border-gray-100">
      <button v-for="(tab, index) in filteredTabs" :key="index" @click="activeTab = tab.slug"
        class="pb-2 text-sm border-b-2 font-medium" :class="activeTab == tab.slug
    ? 'text-blue-600 border-blue-600'
    : 'text-gray-400 border-transparent'
    ">
        <span v-html="tab.icon"></span>
        {{ tab.title }}
      </button>
    </div>

    <!--  Form  -->
    <form class="" @submit.prevent="save()">
      <div v-show="activeTab == 'main'">
        <main-customer-settings :customer="customer" v-on:customer-found="setCustomer" :status="status"
          v-on:changed="status = ''" />
      </div>

      <div v-if="activeTab == 'history'">
        <customer-history :id="customer.id" :customer="customer" />
      </div>

      <div v-if="activeTab == 'prescriptions'">
        <customer-prescription :customer="customer" />
      </div>

      <div v-if="activeTab == 'myacuvue'">
        <customer-myacuvue :customer="customer" />
      </div>

      <div class="flex flex-row items-center justify-between mt-8" v-if="activeTab == 'main'">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary">
          Сохранить
        </button>

        <button type="button" @click="remove()" v-if="customer.id && $requireRole('admin')"
          class="py-1 px-4 bg-red-100 text-red-500 rounded-md font-medium text-xs opacity-50 hover:opacity-100">
          Удалить
        </button>
      </div>
    </form>

    <div v-if="activeTab == 'main'">
      <div class="border-green-200 border text-green-600 py-2 text-sm rounded-md px-4 mt-2"
        v-if="this.status == 'save-success' && !busy">
        Сохранили успешно.
      </div>

      <div v-if="this.status == 'such_phone_exists'" class="alert">
        Клиент с таким телефоном уже есть.
      </div>

      <div class="border-green-200 border text-green-600 py-2 text-sm rounded-md px-4 mt-2"
        v-if="this.status == 'delete-success' && !busy">
        Пользователь удален.
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";

import Customer from "../../repo/CustomerRepo";
import Loading from "../Loading";
import MainCustomerSettings from "./MainCustomerSettings.vue";
import CustomerHistory from "./CustomerHistory.vue";
import { CustomerSchema } from "../../repo/CustomerRepo";
import CustomerPrescription from "@/components/customers/CustomerPrescription.vue";
import CustomerMyacuvue from "@/components/customers/CustomerMyacuvue.vue";

export default {
  name: "edit-customer",
  components: {
    CustomerMyacuvue,
    CustomerPrescription,
    Loading,
    Modal,
    MainCustomerSettings,
    CustomerHistory
  },
  mixins: [ModalMixin],
  props: ["id", "opticId", "showSearch"],
  data() {
    return {
      activeTab: "main",
      tabs: [
        {
          title: "Основное",
          slug: "main"
        },
        {
          title: "История приемов",
          slug: "history",
          icon: '<i class="fas fa-customer mr-1 text-xs"></i>',
          haveToBeSaved: true
        },
        {
          title: "Рецепты",
          slug: "prescriptions",
          icon: '<i class="fas fa-customer mr-1 text-xs"></i>',
          haveToBeSaved: true
        },
        {
          title: "My Acuvue",
          slug: "myacuvue",
          icon: '<i class="fas fa-customer mr-1 text-xs"></i>',
          haveToBeSaved: false
        }
      ],
      loading: false,
      busy: false,
      customer: {},
      status: "",
      searchByPhoneString: ""
    };
  },
  mounted() {
    if (this.id !== -1 && this.id != "") {
      this.get();
    } else {
      this.customer = { ...CustomerSchema };
      if (this.opticId) {
        this.customer.opticId = this.opticId;
      }
    }
  },
  methods: {
    customerFound(customer) {
      this.setCustomer(customer);
    },
    setCustomer(data) {
      console.log(data);
      this.customer = data;
      this.searchByPhoneString = data.phone;
    },
    async save() {
      this.busy = true;
      let vm = this;

      let { success, msg, data } = await Customer.update(this.customer);

      if (success) {
        this.customer = data;
        this.status = "save-success";
      } else {
        this.status = msg;

        if (msg == "such_phone_exists") {
          document.getElementById("phone").scrollIntoView();
        }
      }

      setTimeout(() => {
        vm.busy = false;
      }, 300);

      //this.$events.$emit("customer-updated", this.customer.id);
    },
    async get() {
      this.loading = true;
      const { data, msg, success } = await Customer.getByid(this.id);
      if (success) {
        this.customer = data;
      }
      this.loading = false;
    },
    async remove() {
      if (!confirm("Удалить этого клиента?")) {
        return;
      }

      this.loading = true;
      const { data, msg, success } = await Customer.delete(this.id);
      if (success) {
        this.status = "delete-success";
        this.close();
      }
      /**@todo: add error handlers */
      this.loading = false;
    }
  },
  computed: {
    fio() {
      return [this.customer.lastname, this.customer.firstname, this.customer.thirdname].join(" ");
    },
    filteredTabs() {
      return this.tabs.filter(tab => {
        if (tab.haveToBeSaved && this.customer.id) {
          return true;
        }

        if (!tab.haveToBeSaved) {
          return true;
        }

        return false;
      });
    }
  }
};
</script>
