<template>
    <modal :styles="'md:w-160 md:min-w-160'">
        <div>
            <div @drop.prevent="handleDrop" @dragover.prevent
                class="bg-gray-50 relative rounded-md px-8 text-sm py-10 text-center border-2 border-dashed border-gray-300">
                <p>Перетащите фотографии, или выберите фотографии для загрузки</p>
                <input type="file" @change="handleFileInput" multiple ref="fileInput" hidden />
                <button class="btn-add mt-2 btn-sm" @click="selectFiles">Выбрать фотографии</button>
                <loading v-if="processing" />
            </div>

            <div class="grid grid-cols-5 gap-2 mt-4" v-if="files.length > 0">
                <div v-for="(file, index) in sortedFiles" :key="index" class="relative group">
                    <img :src="file.url" :alt="'Photo ' + (index + 1)" />
                    <div class="absolute group-hover:flex w-full items-center min-h-full text-center hidden top-0 left-0  h-full cursor-pointer bg-black bg-opacity-50"
                        @click="confirmRemove(index)">
                        <button class="text-red-600 mx-auto text-4xl">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-center justify-between mt-4" v-if="files.length > 0">
                <button-cancel @click="resetFiles">Отмена</button-cancel>
                <button-primary @click="submitFiles" :disabled="!files.length" :busy="busy">Отправить</button-primary>
            </div>

            <div class="relative text-center" v-if="busy">
                <div class="font-semibold text-blue-500 py-4 text-sm">
                    Загружается...Ожидайте пожалуйста.
                </div>
                <spinner class="" />
            </div>

            <alert v-if="hasError" error>
                {{ message }}
            </alert>

            <alert success v-if="hasSuccess">
                <strong>Документ успешно загружен.</strong>
                <div>
                    Тип документа: {{ doc.documentType }}
                    <div v-if="doc.trashBoxNumber">Уберите документ в коробку {{ doc.trashBoxNumber }}</div>
                </div>
            </alert>

            <button-primary v-if="hasSuccess" @click="hasSuccess = false">Добавить другой</button-primary>

        </div>
    </modal>
</template>
<script>

export default {
    data() {
        return {
            files: [],
            message: '',
            hasError: false,
            busy: false,
            processing: false,
            hasSuccess: false,
            doc: {}
        }
    },
    mounted() {
        this.busy = false;
        this.processing = false;
        this.files = [];
        this.hasError = false;
        this.hasSuccess = false;
    },
    methods: {
        selectFiles() {
            this.$refs.fileInput.click();
        },
        handleFileInput(event) {
            this.addFiles(event.target.files);
        },
        handleDrop(event) {
            this.addFiles(event.dataTransfer.files);
        },
        addFiles(fileList) {
            let vm = this;
            vm.hasError = false;
            vm.hasSuccess = false;
            vm.doc = null;

            this.processing = true;
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                const url = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64String = e.target.result;
                    this.files.push({ file, url: base64String, lastModified: file.lastModified })
                };

                reader.readAsDataURL(file);  // Convert the file to Base64
            }

            setTimeout(() => {
                vm.processing = false;
            }, 500);
        },

        confirmRemove(index) {
            if (confirm('Удалить это изображение?')) {
                this.files.splice(index, 1);
            }
        },
        resetFiles() {
            this.files = [];
            this.message = '';
            this.hasError = false;
        },
        async submitFiles() {

            this.hasError = false;
            this.busy = true;
            try {
                let preparedFiles = [...this.sortedFiles].map(file => file.url);
                const { success, msg, data } = await this.$api.post('/documents/upload', { images: preparedFiles });

                if (!success) {
                    this.hasError = true;
                    this.message = msg;
                } else {
                    this.doc = data.doc;
                    if (this.doc.status == 'error') {
                        this.hasError = true;
                        this.message = this.doc.error;
                    } else {
                        this.hasSuccess = true;
                        this.files = [];
                    }
                }

            } catch (error) {
                this.message = error.message || 'Error uploading photos';
                this.hasError = true;
            }

            this.busy = false;
        },
    },
    computed: {
        sortedFiles() {
            return this.files.slice().sort((a, b) => a.lastModified - b.lastModified);
        }
    }
}
</script>