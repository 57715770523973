<template>
  <div>
    <div class="flex flex-row items-center mb-2">
      <div v-if="this.ma">
        <div>{{ma.consumer.lastName}} {{ma.consumer.firstName}}</div>
        <div>
          <img src="/img/myacuvue-logo.svg" class="w-4 inline" alt=""> {{ma.consumer.myAcuvueId}} (баллы: {{ma.points.myAcuvuePoints}})<br>
        </div>
      </div>

      <template v-else-if="maBusy">
          <svg
            class="animate-spin h-4 w-4 text-blue-600 ml-auto mr-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </template>
        <template v-else-if="maError">
          <!--      <error>{{ maError }}</error>-->
        </template>
        <template v-else>
          <!--      <error>Не удалось получить данные из системы MyAcuvue</error>-->
        </template>
      </div>


  </div>
</template>

<script>
import MyAcuvueRepo from "../../repo/MyAcuvueRepo";

export default {
  props: ["phone"],
  data() {
    return {
      ma:null,
      maError:null,
      maBusy: false
    };
  },
  name: "search-myacuvue-by-phone",
  async mounted() {
    this.getMyAcuvueData()
  },
  methods: {
    async getMyAcuvueData() {
      this.maBusy = true;
      try {
          const maData = await MyAcuvueRepo.getByPhone(this.phone);
          if (maData.success) {
            const maDataDetail = await MyAcuvueRepo.getById(maData.data.consumer.myAcuvueId);
            this.ma = maDataDetail.data
            this.$emit('acuvue', this.ma.consumer)
          } else {
            this.maError = maData.msg
          }

      } catch (err) {
        this.maError = err
      }

      this.maBusy = false;
    },

  },
  computed: {}
};
</script>
