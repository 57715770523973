import api from "../api/api";

const resource = "/myacuvue";

export default {
  getById(myacuvueId) {
    return api.call(`${resource}/${myacuvueId}`, { method: "get" });
  },
  getByPhone(phone) {
    return api.call(`${resource}/phone/${phone}`, { method: "get" });
  }
};