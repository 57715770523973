<template>
  <div class="overflow-x-auto">
    <div v-if="this.customer.acuvue.fittedBrands">
      <table class="data-table">
        <thead>
        <tr class="font-semibold text-xs text-gray-600">
          <th class="px-4 py-3">Линзы</th>
          <th class="">Параметры</th>
          <th class="">Комментарий</th>
        </tr>
        </thead>
        <tbody class="text-sm">
        <tr v-for="item in this.customer.acuvue.fittedBrands">
          <td>
            <div class="font-semibold mb-2"><span>{{ formatModel(item.brandId) }}</span></div>
          </td>
          <td>
            <div class="grid grid-cols-6 text-xs font-semibold text-gray-600 mb-2 text-center">
              <div></div>
              <div>Sph</div>
              <div>R</div>
              <div>Cyl</div>
              <div>Axis</div>
              <div>Add</div>
            </div>
            <div class="grid grid-cols-6 gap-x-2 text-center">
              <!-- od -->
              <div>OD</div>
              <div>
                <span v-if="item.rightEye.find(e=>e.property=='POWER')">
                  {{item.rightEye.find(e=>e.property=='POWER').value}}
                </span>
              </div>
              <div>
                <span v-if="item.rightEye.find(e=>e.property=='BASE_CURVE')">
                  {{item.rightEye.find(e=>e.property=='BASE_CURVE').value}}
                </span>
              </div>
              <div>
                <span v-if="item.rightEye.find(e=>e.property=='CYLINDER')">
                  {{item.rightEye.find(e=>e.property=='CYLINDER').value}}
                </span>
              </div>
              <div>
                 <span v-if="item.rightEye.find(e=>e.property=='AXIS')">
                  {{item.rightEye.find(e=>e.property=='AXIS').value}}
                </span>
              </div>
              <div>
                 <span v-if="item.rightEye.find(e=>e.property=='ADDITION')">
                  {{item.rightEye.find(e=>e.property=='ADDITION').value}}
                </span>
              </div>
              <!-- os -->
              <div>OS</div>
              <div>
                <span v-if="item.leftEye.find(e=>e.property=='POWER')">
                  {{item.leftEye.find(e=>e.property=='POWER').value}}
                </span>
              </div>
              <div>
                <span v-if="item.leftEye.find(e=>e.property=='BASE_CURVE')">
                  {{item.leftEye.find(e=>e.property=='BASE_CURVE').value}}
                </span>
              </div>
              <div>
                <span v-if="item.leftEye.find(e=>e.property=='CYLINDER')">
                  {{item.leftEye.find(e=>e.property=='CYLINDER').value}}
                </span>
              </div>
              <div>
                <span v-if="item.leftEye.find(e=>e.property=='AXIS')">
                  {{item.leftEye.find(e=>e.property=='AXIS').value}}
                </span>
              </div>
              <div>
                <span v-if="item.leftEye.find(e=>e.property=='ADDITION')">
                  {{item.leftEye.find(e=>e.property=='ADDITION').value}}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div>{{item.comments}}</div>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
    <template v-else-if="maBusy">
      <div class="w-full text-center">
        <svg
          class="animate-spin h-6 w-6 text-blue-600 ml-auto mr-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </template>
    <template v-else-if="maError">
      <error>{{ maError }}</error>
    </template>
    <template v-else>
      <error>Не удалось получить данные из системы MyAcuvue</error>
    </template>
  </div>
</template>

<script>
import MyAcuvueRepo from "../../repo/MyAcuvueRepo";

export default {
  props: ["id", "customer"],
  data() {
    return {
      ma:null,
      maError:null,
      maBusy: false
    };
  },
  name: "customer-myacuvue",
  async mounted() {
    this.getMyAcuvueData()
  },
  methods: {
    async getMyAcuvueData() {
      this.maBusy = true;
      try {
        const curMAid =  this.customer?.acuvue?.consumer?.myAcuvueId ?? null;
        if(curMAid) {
          const myacuvueData = await MyAcuvueRepo.getById(this.customer.acuvue.consumer.myAcuvueId);
          (myacuvueData.success) ? this.customer.acuvue = myacuvueData.data : this.maError = myacuvueData.msg

        } else {
          const myacuvueData = await MyAcuvueRepo.getByPhone(this.customer.phone);
          (myacuvueData.success) ? this.customer.acuvue = myacuvueData.data : this.maError = myacuvueData.msg
        }
      } catch (err) {
        this.maError = err
      }

      this.maBusy = false;
    },
    formatModel(source_model) {
      const allBrands = [
        {'abbr': '1DATE', 'full': '1-Day Acuvue TruEye 30'},
        {'abbr': 'AOH', 'full': 'Acuvue Oasys 6'},
        {'abbr': '1DAM', 'full': '1-Day Acuvue Moist 30'},
        {'abbr': 'AOHfA', 'full': 'Acuvue Oasys for astigmatism'},
        {'abbr': '1DAMfA', 'full': '1-Day Acuvue Moist for Astigmatism'},
        {'abbr': '1DAD', 'full': '1-Day Acuvue define'},
        {'abbr': 'AOH1D', 'full': 'Acuvue Oasys 1-Day'},
        {'abbr': '1DAMM', 'full': '1-Day Acuvue Moist Multifocal'},
        {'abbr': 'AOH1DfA', 'full': 'Acuvue Oasys 1-Day for Astigmatism'},
        {'abbr': 'ARL', 'full': 'Acuvue Revitalens 300 мл'},
        {'abbr': 'AOwT', 'full': 'Acuvue Oasys with Transitions'},
        {'abbr': 'DAMM', 'full': ''}
      ];
      const brand = allBrands.find(brand => brand.abbr == source_model);
      return brand ? brand.full : source_model
    }
  },
  computed: {}
};
</script>
